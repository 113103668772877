* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.d-flex {
  display: flex;
}

.f-col {
  flex-direction: column;
}

.f-wrap {
  flex-wrap: wrap;
}

.j-center {
  justify-content: center;
}

.j-end {
  justify-content: flex-end;
}

.a-center {
  align-items: center;
}

.space-even {
  justify-content: space-evenly;
}

.space-btw {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.p-1 {
  padding: 1rem;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.text-center {
  text-align: center;
}

.text-green {
  color: #80b233;
}

.text-red {
  color: #c56312;
}

.overflow-hidden {
  overflow: hidden;
}

body {
  background-color: #331d2c;
  color: #efe1d1;
}

input,
textarea {
  border-radius: 5px;
  padding: 1rem;
  font-family: sans-serif;
  border: none;
  outline: none;
  font-size: 1rem;
}

.circle-img {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 50%;
  border: 10px solid;
  border-color: rgba(239, 225, 209, 0) #a05344 #a05344 #a05344;
  animation: spin-border linear 20s infinite;
}

.circle-img > img {
  width: 100%;
  animation: spin-image linear 20s infinite;
}

.info h1 {
  font-size: 2rem;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing 1s steps(15, end) forwards;
}

.info h2 {
  font-size: 1.7rem;
}

.social a {
  font-size: 2.5rem;
  color: #efe1d1;
  transition: 0.3s;
}

.social a:hover {
  color: #a05344;
}

.btn-main, .btn-link, .btn-send, .btn-secondary {
  cursor: pointer;
  border: #efe1d1 solid 1px;
  border-radius: 30px;
  padding: 1rem 2rem;
  background-color: #331d2c;
  color: #efe1d1;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.btn-main:hover, .btn-link:hover, .btn-send:hover, .btn-secondary:hover {
  background: linear-gradient(to right, #a05344, #734046);
}

.btn-secondary {
  padding: 0.5rem 1rem;
  background-color: #a05344;
  border-radius: 5px;
  border: none;
}

.btn-send {
  border: none;
  background: linear-gradient(to right, #ffc06c, #a05344);
  color: #331d2c;
}

.btn-send:hover {
  background: linear-gradient(to right, #a05344, #ffc06c, #a05344);
}

.btn-link {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 5px 1px rgba(167, 130, 149, 0.75);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(167, 130, 149, 0.75);
  -moz-box-shadow: 0px 0px 5px 1px rgba(167, 130, 149, 0.75);
  border: none;
}

.btn-link span {
  margin-left: 0.5rem;
}

.contact-form {
  width: 500px;
  max-width: 95vw;
}

.contact-form h1,
.contact-form h2 {
  margin: 1rem 0;
  font-weight: normal;
}

.contact-form button {
  border-radius: 5px;
}

.icons-wrapper {
  max-width: 90vw;
}

.tech-icon {
  position: relative;
}

.tech-icon > img {
  height: 50px;
}

.tech-icon .name {
  position: absolute;
  height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
}

.tech-icon .name > div {
  background-color: rgba(63, 46, 62, 0.8);
  padding: 3px;
  border-radius: 5px;
  color: #efe1d1;
}

.tech-icon .name:hover {
  opacity: 1;
}

.project-entry {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  color: #efe1d1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5rem;
  box-shadow: 0px 0px 5px 1px rgba(167, 130, 149, 0.75);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(167, 130, 149, 0.75);
  -moz-box-shadow: 0px 0px 5px 1px rgba(167, 130, 149, 0.75);
}

.project-entry h2 {
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-top: 0.2rem;
}

.project-entry a {
  text-decoration: none;
}

.project-entry li {
  margin-left: 2rem;
}

.project-about {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.project-about p {
  padding-bottom: 10px;
}

.project-img {
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  border: #3f2e3e solid 1px;
  border-radius: 3px;
}

.section-title {
  margin-top: 2rem;
  background-color: #734046;
  padding: 1rem;
  text-align: center;
  font-size: 2.8rem;
}

.toolbar {
  width: 100%;
  background-color: #331d2c;
  padding-top: 0.5rem;
  display: flex;
  color: #efe1d1;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 3px rgba(160, 83, 68, 0.75);
  -webkit-box-shadow: 0px 0px 10px 3px rgba(160, 83, 68, 0.75);
  -moz-box-shadow: 0px 0px 10px 3px rgba(160, 83, 68, 0.75);
}

.nav {
  display: none;
}

.nav-icon {
  width: 100%;
  display: flex;
}

.menu-item {
  color: #efe1d1;
  text-decoration: none;
  border-bottom: #3f2e3e solid 2px;
  transition: 0.3s;
  font-size: 1.1rem;
  padding: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
  border-bottom: #331d2c solid 3px;
}

.menu-item:hover {
  border-bottom: #734046 solid 3px;
}

.active {
  color: #fad897;
}

.about-text {
  max-width: 600px;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.5rem;
}

.about-text h1 {
  margin: 2rem 0;
}

.big-icon {
  font-size: 3rem;
}

.social-link {
  text-decoration: none;
  gap: 0.5rem;
}

.social-link svg {
  font-size: 3.5rem;
}

.social-link b {
  font-size: 1rem;
}

.loading-icon {
  width: 25px;
  height: 25px;
  background-image: url("images/loading.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.project-text {
  margin-left: 1rem;
  line-height: 1.5rem;
  text-align: justify;
}

.project-entry-full {
  max-width: 950px;
}

.project-entry-full img {
  width: 100%;
}

.mobile-version-album {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.mobile-version-album img {
  width: 100%;
}

.go-back {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  gap: 0.5rem;
  transition: 0.3s;
  cursor: pointer;
  padding: 1rem;
}

.go-back svg {
  font-size: 1.5rem;
}

.go-back:hover {
  color: #a05344;
}

.scroll-up-button {
  height: 45px;
  width: 45px;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  color: #efe1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  background-color: #734046;
  border: #a78295 solid 1px;
}

.scroll-up-button:hover {
  background-color: #a05344;
}

.scroll-up-button.visible {
  opacity: 1;
}

.link-light a {
  color: #efe1d1;
  text-decoration: underline;
  transition: 0.3s;
}

.link-light a:hover {
  color: #6f93c4;
}

.color-changing-text {
  font-weight: 600;
  background: linear-gradient(to right, #ffc06c, #a05344);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}

@keyframes spin-border {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-image {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (min-width: 600px) {
  .info h1 {
    font-size: 3rem;
  }
  .info h2 {
    font-size: 2rem;
  }
  .nav {
    width: 100%;
    display: flex;
  }
  .nav-icon {
    display: none;
  }
  .project-about {
    grid-template-columns: 200px 1fr;
  }
  .project-about p {
    padding: 10px 0 0;
  }
  .mobile-version-album {
    grid-template-columns: 1fr 1fr;
  }
  .active {
    font-weight: 600;
    background: linear-gradient(to right, #ffc06c, #a05344);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

@media (min-width: 864px) {
  .f-lg-row {
    flex-direction: row;
  }
  .portfolio {
    max-width: 1000px;
  }
  .project-about {
    grid-template-columns: 300px 1fr;
  }
}

@media (min-width: 1450px) {
  .portfolio {
    max-width: 100vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .project-about {
    grid-template-columns: 200px 1fr;
  }
}

@media (prefers-color-scheme: dark) {
  .btn-send {
    background: linear-gradient(to right, #ffc06c, #a05344);
  }
  .color-changing-text {
    background: linear-gradient(to right, #ffc06c, #a05344);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
}

@media (prefers-color-scheme: dark) and (min-width: 600px) {
  .active {
    font-weight: 600;
    background: linear-gradient(to right, #ffc06c, #a05344);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}
